function printPdfDocument (url) {
    var iframe = this._printIframe;
    if (!this._printIframe) {
        iframe = this._printIframe = document.createElement('iframe');
        document.body.appendChild(iframe);

        iframe.style.display = 'none';
        iframe.onload = function() {
            setTimeout(function() {
                iframe.focus();
                iframe.contentWindow.print();
            }, 1);
        };
    }

    iframe.src = url;
}

jQuery(document).ready(function(){

    jQuery(document).foundation();

    // Disable right mouse click Script
    jQuery('body').on("contextmenu",function(){
        return false;
    });

	jQuery('body').mousedown(function(e){
		if(e.which == 3) e.preventDefault;
	});

    jQuery('form.translate').validate({
        rules: {
            language: {
                required: true
            },
            translation: {
                required: true
            }
        },
        highlight: function(element) {
            //$(element).closest('.form-item').addClass('form-error');
            $(element).closest('label').addClass('is-invalid-label');
            $(element).closest('fieldset').addClass('is-invalid-label');

        },
        unhighlight: function(element) {
            //$(element).closest('.form-item').removeClass('form-error');
            $(element).closest('label').removeClass('is-invalid-label');
            $(element).closest('fieldset').removeClass('is-invalid-label');

        },
        errorElement: 'span',
        errorClass: 'form-error',
        messages: {
            language: {
                required: "Please select a language"
            },
            translation: {
                required: "Please select a translation."
            }
        },

        errorPlacement: function(error, element)
        {
            if ( element.is(":radio") ) {
                error.prependTo( element.parents('.fieldset') );
            } else if(element.parent('.input-group').length) {
                error.insertAfter(element.parent());
            } else {
                error.insertAfter(element);
            }
        }
    });

});